import React from 'react'
import TeacherAttend from '../../../../components/teacherReg/TeacherAttend'

const TeachersAtt = () => {
  return (
    <div>
      <TeacherAttend />
    </div>
  )
}

export default TeachersAtt