import React from 'react'
// import SmsLanding from '../../../components/sms-landing/SmsLanding'
import SmsLogin from '../../../components/sms-landing/SmsLogin'

const SmsLP = () => {
  return (
    <div>
        <SmsLogin />
    </div>
  )
}

export default SmsLP