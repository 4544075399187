export const weeks = [
  {
    id: "Week 1",
    name: "Week 1",
  },
  {
    id: "Week 2",
    name: "Week 2",
  },
  {
    id: "Week 3",
    name: "Week 3",
  },
  {
    id: "Week 4",
    name: "Week 4",
  },
  {
    id: "Week 5",
    name: "Week 5",
  },
  {
    id: "Week 6",
    name: "Week 6",
  },
  {
    id: "Week 7",
    name: "Week 7",
  },
  {
    id: "Week 8",
    name: "Week 8",
  },
  {
    id: "Week 9",
    name: "Week 9",
  },
  {
    id: "Week 10",
    name: "Week 10",
  },
  {
    id: "Week 11",
    name: "Week 11",
  },
  {
    id: "Week 12",
    name: "Week 12",
  },
  {
    id: "Week 13",
    name: "Week 13",
  },
  {
    id: "Week 14",
    name: "Week 14",
  },
  {
    id: "Week 15",
    name: "Week 15",
  },
];

export const days = [
  {
    id: "Monday",
    name: "Monday",
  },
  {
    id: "Tuesday",
    name: "Tuesday",
  },
  {
    id: "Wednesday",
    name: "Wednesday",
  },
  {
    id: "Thursday",
    name: "Thursday",
  },
  {
    id: "Friday",
    name: "Friday",
  },
];

export const terms = [
  {
    id: "First Term",
    name: "First Term",
  },
  {
    id: "Second Term",
    name: "Second Term",
  },
  {
    id: "Third Term",
    name: "Third Term",
  },
];

export const az = [
  {
    id: "a-z",
    name: "A-Z",
  },
  {
    id: "z-a",
    name: "Z-A",
  },
];
