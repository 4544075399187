import React from 'react';

import SchoolCd from "../../../components/school-code/SchoolCd";

const SchoolCode = () => {
  return (
    <div>
        <SchoolCd />
    </div>
  )
}

export default SchoolCode