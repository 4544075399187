import React from 'react';
import RegStu from '../../../../components/student-reg/RegStu';

const StudentReg = () => {
  return (
    <div>
      <RegStu />
    </div>
  )
}

export default StudentReg