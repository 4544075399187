import React from 'react'
import Login from '../../../components/login/Login'

const SmsLogin = () => {
  return (
    <div>
        <Login />
    </div>
  )
}

export default SmsLogin