import React from 'react';
import SchMgt from '../../../../components/school-mgt/SchMgt';

const SchoolMgt = () => {
  return (
    <div>
      <SchMgt />
    </div>
  )
}

export default SchoolMgt