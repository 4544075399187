import React from 'react'
import NewsEvent from '../../components/news&events/NewsEvent'

const News = () => {
  return (
    <div>
      <NewsEvent btnDisplay={"none"} />
    </div>
  )
}

export default News