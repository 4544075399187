import React from 'react'
import Teacher from '../../../components/teacher-portal/Teacher'

const Teacherportal = () => {
  return (
    <div>
        <Teacher />
    </div>
  )
}

export default Teacherportal