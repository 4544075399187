import React from 'react'
import Notification from '../../../../components/notification/Notification'

const SchAdminNots = () => {
  return (
    <div>
      <Notification />
    </div>
  )
}

export default SchAdminNots