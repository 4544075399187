import React from 'react'
import AttendStu from '../../../../components/teacherReg/AttendStu'

const StudentAtt = () => {
  return (
    <div>
      <AttendStu />
    </div>
  )
}

export default StudentAtt